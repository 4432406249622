import { createStore } from 'vuex'
import { getChainId,setChainId,getToken } from '@/utils/auth'
import {MemberView,Loginout} from '@/api'
import App from "@/api/app-ether"
import {router} from '@/router'


export const store = createStore({
  state: {
    isConnect:false,
    Token:'',
    chain:'',
    address:"",
    member:'',
  },
  mutations: {
    set_Connect:(state, data)=>{
      state.isConnect = data
    },
    set_Token:(state, data)=>{
      state.Token = data
    },
    set_Chain:(state, data)=>{
      state.chain = data
    },
    set_address:(state, data)=>{
      state.address = data
    },
    set_member:(state, data)=>{
      state.member = data
    },
  },
  actions:{
    getAccountData({ commit }){
      const accountAddr =  App.TronLink.getCurrentAccount()
      // commit('set_address',accountAddr)
      if(accountAddr&&getToken()){
        commit('set_address',accountAddr)
      }
    },
    getClear({ commit }){
      commit('set_address','')
      commit('set_Token','')
      commit('set_Chain','')
      commit('set_member','')
    },
    getReset({ commit }){
      Loginout().then(res=>{})
      commit('set_address','')
      commit('set_Token','')
      commit('set_Chain','')
      commit('set_member','')
      
    },
    getChain({ commit ,state}){
      App.TronLink.getChain().then(res=>{
        setChainId(res)
        commit('set_Chain', res)
        console.log(res,state.chain)
        // console.log(res)
      })
    },
    getMemberView({ commit }){
      return new Promise((resolve, reject) => {
        MemberView().then(response => {
            const data = response.Data
            commit('set_member', data.member)
            if(data.member){
              // if(data.member.GradeOID=='UnActivated'||data.member.GradeOID=='Activated'){
              //   router.push('/empty')
              // }
            }
            resolve(data)
          }).catch(error => {
            reject(error)
          })
      })
    } ,
  },
  getters: {},
})
