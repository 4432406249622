import request from '../utils/request'
import App from "@/api/app-ether"
import {BEP20_ABI} from './BEP20_ABI'
import { showLoadingToast,closeToast,showToast } from 'vant';
const pay_USDT_Decimal = 18;
const token_Decimal = 6;
BigNumber.default.config({ DECIMAL_PLACES: 18 })
import {checkConnect,getToast} from "./base"
//-----chainId===测试
const payContract = "TWMiAyJBDzZygj82vqzzhYazpG9nDyZKwR";
const tradeContract = "TWUVfsUC2jqTF4PLHWXfaN46fk5sLYKJPo";

import {toToast} from '@/utils'

//  初始化接口
export function ValidateCode(data) {
  return request({
    url: '/Qt/common/GetValidateCode',
    method: 'post',
    data,
  })
}

export function MemberName(data) {
  return request({
    url: '/Qt/DefaultF/GetMemberInfo',
    method: 'post',
    data,
  })
}

export function CarouselImage(params) {
  return request({
    url: '/Qt/DefaultF/GetCarouselImage',
    method: 'get',
    params,
  })
}

export function Login(data) {
  showLoadingToast({
      duration:0,
      message:'登录中...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/DefaultF/RegeditLogins',
    method: 'post',
    data,
  })
}



export function ForgetPasswordForTel(data) {
  return request({
    url: '/Qt/DefaultF/ForgetPasswordForTel',
    method: 'post',
    data,
  })
}



export function EditMemberLoginPwd(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditMemberLoginPwd',
    method: 'post',
    data,
  })
}

export function NewNews(params) {
  return request({
    url: '/Qt/DefaultF/NewNews',
    method: 'get',
    params,
  })
}



export function GetNewsList(params) {
  return request({
    url: '/Qt/NoticeF/GetNewsList',
    method: 'get',
    params,
  })
}

export function GetNewsDetail(params) {
  return request({
    url: '/Qt/NoticeF/GetNewsDetail',
    method: 'get',
    params,
  })
}

export function MyTeamList(params) {
  return request({
    url: '/Qt/MemberF/MyTeamList',
    method: 'get',
    params,
  })
}

export function EditMemberView(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditMemberView',
    method: 'post',
    data,
  })
}
export function getMemberView(params) {
  return request({
    url: '/Qt/MemberF/EditMemberView',
    method: 'get',
    params,
  })
}

export function MemberView(params) {
  return request({
    url: '/Qt/MemberF/MemberView',
    method: 'get',
    params,
  })
}

export function uploadimg(data) {
  showLoadingToast({
    duration:0,
    message: '上传中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/common/uploadimg',
    method: 'post',
    data,
  })
}


export function ReferralLink(params) {
  return request({
    url: '/Qt/MemberF/GetReferralLink',
    method: 'get',
    params,
  })
}


export function AwardsRecord(params) {
  return request({
    url: '/Qt/FinanceF/GainAwardsRecord',
    method: 'get',
    params,
  })
}


export function Loginout(data) {
  // showLoadingToast({
  //   duration:0,
  //   message: '退出中...',
  //   forbidClick: true,
  // });
  return request({
    url: '/Qt/MemberF/Loginout',
    method: 'post',
    data,
  })
}


export function TransfersConfig(params) {
  return request({
    url: '/Qt/FinanceF/SubmitTransfers',
    method: 'get',
    params,
  })
}


export function SubmitTransfers(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/SubmitTransfers',
    method: 'post',
    data,
  })
}

export function GetBillflowList(params) {
  return request({
    url: '/Qt/FinanceF/GetBillflowList',
    method: 'get',
    params,
  })
}


export function GetSubmitWithdraw(params) {
  return request({
    url: '/Qt/FinanceF/SubmitWithdraw',
    method: 'get',
    params,
  })
}


export function GetWithdraws(params) {
  return request({
    url: '/Qt/FinanceF/GetWithdraws',
    method: 'get',
    params,
  })
}


export function SubmitWithdraw(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/SubmitWithdraw',
    method: 'post',
    data,
  })
}






 //转账
//  function UsdtTrans(toAddr1,amount1,callback){
//   ERC20Transfer(tokenContract, token_Decimal,toAddr1,amount1,res=>{
//     if(res) callback(res)
//     else  callback('')
//   });
// }
// //token转账
// export function tokenTrans(toAddr1,amount1,callback){
//   ERC20Transfer(tokenContract, token_Decimal,toAddr1,amount1,res=>{
//     if(res) callback(res)
//     else  callback('')
//   });
// }

export function ERC20Transfer(tokenAddr, decimal,toAddr1,amount1,callback) {

  if(!checkConnect()) return
  var ownerAddr = App.MetaMask.getCurrentAccount();
  var toAddr = toAddr1;
  var amount = amount1;
  var realAmount = BigNumber(amount).mult(BigNumber(10).pow(decimal));
  App.MetaMask.getContractInstance(tokenAddr, BEP20_ABI, function(error, instance) {
      if (error) {
          console.log(error);
          toToast(error.message)
          callback(null)
          return;
      }
      getToast()
      instance.methods.transfer(toAddr, realAmount).send({ from: ownerAddr }).then(function(result) {
          console.log(result.transactionHash)
          callback(result.transactionHash)
          console.log("transfer callback", result);
      }).catch(function(error) {
        toToast(error.message);
        // callback(null)
        // // console.log(error)
        // var obj = App.MetaMask.getError(error);
        // toToast(obj.message);

    });
  })
}