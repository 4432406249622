<template>
  <div id="App">
    <router-view v-slot="{Component}">
      <Transition name="fade-transform" mode="out-in">
        <component :is="Component"></component>	
      </Transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted() {
    
  },
}
</script>
<style lang="less">
.van-field__control:disabled{
  color:#333!important;
  -webkit-text-fill-color:#333!important;
}
.van-field__body  input::-webkit-input-placeholder{
  color:#777777;
}
.van-field__body  input::-moz-input-placeholder{
  color:#777777;
}
.van-field__body  input::-ms-input-placeholder{
  color:#777777;
}
.van-field__body  input:-ms-input-placeholder{
  color:#777777;
}
.van-field__body input::placeholder{
  color:#777777!important;
}
.home-wrap{
  .van-nav-bar{
    background: #F4F9FC;
  }
  .van-notice-bar{
    padding:0!important;
  }
} 

.wobble-active {
  animation: waggle 3s infinite;
}

.van-cell{
// background: #1c1c1e!important;
}
.tan-box{
		width: 80vw;
		min-height: 26vh;
		padding-top: 20px;
		// background: url("@/assets/imgs/default_bg_pop.png") no-repeat left top / 100% 100%;
		overflow: hidden;
	}
  .width-80{
    width: 80%;
    margin: 0 auto;
  }
  .van-cell:after{
    border-color: #ccc!important;
  }

@keyframes waggle {
  0%,
  7% {
    transform: rotate(0);
  }
  15%, 65% {
    transform: rotate(-15deg);
  }
  20%, 70% {
    transform: rotate(10deg);
  }
  25%, 75% {
    transform: rotate(-10deg);
  }
  30%, 80% {
    transform: rotate(6deg);
  }
  35%, 85% {
    transform: rotate(-4deg);
  }
  40%, 57%, 90%,
  100% {
    transform: rotate(0);
  }
}

.title-right{
.stats_icon{
  height: 12px;
}
.more_icon{
  width: 15px;
  height: 15px;
}
}
.form-list{
  .li{
    position: relative;
    line-height: 44px;
  }
  .li::after{
    transform: scaleY(0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    content:'';
    width: 100%;
    height:1px;
    background: linear-gradient(135deg, #F2EEFF 27%, #E3EFFF 41%, #CEF2EB 50%, #EEF0FF 59%, #FFFFFE 69%, #E9EEFF 81%, #CFEAFF 90%, #C8F5DC 100%);
  }
}
.border-fff{
  border:1px solid #fff;
  border-radius: 8px;
}
.form-card{
  width: 92%;
  margin: 0 auto;
  .form{
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 20px;
    .lable{
      width: 33%;
      line-height: 30px;
     text-align: justify;
     text-justify: inter-word; /* 适用于中英文混排 */
     text-align-last: justify;
    }
    .input{
      width:64%;
      border-bottom: 1px solid #fff;
      min-height: 30px;
      .van-cell{
        padding: 0 10px;
        background: #000;
        input{
          height: 30px;
          line-height: 30px;
          color:#fff;
          font-size: 15px;
        }
      }
    }
  }
}
</style>