import { createRouter, createWebHistory,createWebHashHistory} from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/home',
  // },
 
  
  {
    path: '/',
    name: 'Index',
    // redirect: '/',
    component: () => import(/* webpackChunkName: "home" */ '@/pages/index/index.vue'),
  },
  {
    path: '/extract',
    name: 'Extract',
    component: () =>
      import('@/pages/index/extract.vue'),
  },
  {
    path: '/txRod',
    name: 'TxRod',
    component: () =>
      import('@/pages/index/txRod.vue'),
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () =>
      import('@/pages/index/detail.vue'),
  },
  {
    path: '/news',
    name: 'News',
    component: () =>
      import('@/pages/index/news.vue'),
  },
  {
    path: '/empty',
    name: 'Empty',
    component: () =>
      import('@/pages/index/empty.vue'),
  },
 
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// 0-9，a-z, length:8
const randomString = () => {
  return Math.random().toString(36).slice(-8)
}

router.beforeEach((to) => {
  
})

router.afterEach(() => {
  // 隐藏菜单
  // hideMenuItems()
})
