import Web3 from 'web3'
// console.log(web3);
import WalletConnectProvider from "@walletconnect/web3-provider";
const INFURA_ID = '27e4ffa183bc4e7bb9020bfb318bfee7'

import {setToken,setChainId,setChainName,getChainId} from "@/utils/auth"

let web3 ='';
let provider = '';
import {store} from '@/store'
let App = {};
let chainNode = '';
let tronWeb = '';
// import { getLangchange } from '@/language'
// let $lang = getLangchange()

import {_isMobile,toToast} from '@/utils/index'

function init(callback) {

    var error = isErrorTronlinkAndConnect();
    if (error) {
        if (callback) {
            callback(error);

        }
        console.log(error);
        return;
    }
    window.addEventListener('message', handlTronlinkEvent);
    setTimeout(function() { //延迟回调，等待Tronlink连接
        callback(null, "connect success");
    }, 500)

    reconnect();
}

//监听处理Tronlink事件
function handlTronlinkEvent(e) {

    if (e.data.message && e.data.message.action == "tabReply") {
        //连接响应事件
        //console.log("tabReply event", e.data.message)
    } else if (e.data.message && e.data.message.action == "setAccount") {

        console.log("setAccount event", e.data.message, "current address:", e.data.message.data.address);
        disconnect() 

    } else if (e.data && e.data.message && e.data.message.action == "setNode") {

        //console.log("setNode event", e.data.message)

        if (e.data && e.data.message && e.data.message.data && e.data.message.data.node && e.data.message.data.node.fullNode == "https://api.trongrid.io") {


            console.log("main chain-net", e.data);

        } else {
           // console.log("side chain-net", e.data.message.data.node);
        }
        chainNode = e.data.message.data.node.fullNode;
        // Tronlink chrome v3.22.1 & Tronlink APP v4.3.4 started to support 
        if (e.data.message && e.data.message.action == "connect") {
            console.log("connect event", e.data.message.isTronLink)
        }

        // Tronlink chrome v3.22.1 & Tronlink APP v4.3.4 started to support 
        if (e.data.message && e.data.message.action == "disconnect") {
            console.log("disconnect event", e.data.message.isTronLink)
            disconnect() 
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support 
        if (e.data.message && e.data.message.action == "accountsChanged") {
            console.log("accountsChanged event", e.data.message)
            console.log("current address:", e.data.message.data.address)
            disconnect() 
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support  
        if (e.data.message && e.data.message.action == "connectWeb") {
            console.log("connectWeb event", e.data.message)
            console.log("current address:", e.data.message.data.address)
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support      
        if (e.data.message && e.data.message.action == "acceptWeb") {
            console.log("acceptWeb event", e.data.message)
        }
        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support      
        if (e.data.message && e.data.message.action == "disconnectWeb") {
            console.log("disconnectWeb event", e.data.message)
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support     
        if (e.data.message && e.data.message.action == "rejectWeb") {
            console.log("rejectWeb event", e.data.message)
        }
    }
}

function disconnect(){
        
    //    断开连接
        // provider = '';
        setToken('');
        setChainId('')
        web3 = '';
        tronWeb = ''
        if(_isMobile()){
            if(provider){
                provider.disconnect()
            }
        }
        provider = '';
        store.dispatch('getReset')
        setTimeout(()=>{
            location.reload()
        },1000)
    }
export async function todisconnect(){
//    断开连接
    // provider = '';
    setToken('');
    setChainId('')
    web3 = '';
    tronWeb = ''
    if(_isMobile()){
        if(provider){
            provider.disconnect()
        }
    }
    provider = '';
    store.dispatch('getReset')
    setTimeout(()=>{
        location.reload()
    },1000)
}

function reconnect(callback) {
    if(_isMobile() && !window.tronWeb){
        provider = new WalletConnectProvider({
            rpc: {
                5:'https://goerli.infura.io/v3/27e4ffa183bc4e7bb9020bfb318bfee7',
                56:'https://bsc-dataseed1.binance.org/',
                97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
            },
            infuraId: INFURA_ID,
            qrcode: true,//二维码是否开启
            qrcodeModalOptions:{
                mobileLinks:[
                    "tronlink",
                    "imtoken",
                    "tokenpocket"
                ]
            },
        });
        provider.enable().then((res) => {
            tronWeb = new Web3(provider);
            window.tronWeb = tronWeb
            // console.log(provider,res)
            callback(res)
            //账户更改触发的方法
            provider.on("accountsChanged", (accounts) => {
                // console.log(accounts)
                let addr = ''
                if(localStorage.getItem('addr')){
                    addr = localStorage.getItem('addr')
                }
                if(accounts != addr){
                    disconnect()
                    return
                }
                callback(accounts)
                location.reload()
            });
            provider.on("chainChanged", (chainId) => {
                // console.log('切换网络')
                // setChainId(parseInt(chainId))
                location.reload()
            });
            //账户断开的方法
            provider.on("disconnect", (code, reason) => {
                toToast('chain_net had disconnect')
                disconnect()
                location.reload()
    
            });
        }).catch((err) => {
        });

    }else{
    if (!window.tronWeb) {

        toToast(" please install tronLink plugin!");
        return;
    }
    tronWeb
        .request({ method: 'tron_requestAccounts' })
        .then(function(result) {

            if (callback) {
                callback(null, result);
            }
            console.log("connect tronline success,get account", result);
        })
        .catch((err) => {
            toToast(err.message);
            if (err.code === 4001) {
                // 用户拒绝连接
                var error = 'Please connect to TronLink Wallet.';
                if (callback) {
                    callback(error);
                }
            } else {
                console.error(err);
            }
        });
         
    }   

}
async function getContractInstance(contractAddr, callback) {

    var err = isErrorTronlinkAndConnect();
    if (err) {

        callback(err);
        return;
    }
    var instance = await window.tronWeb.contract().at(contractAddr);

    callback(null, instance);

}

//get currentAccount
function getCurrentAccount() {

    var err = isErrorTronlinkAndConnect();
    if (err) {

        console.log(err);
        return null;
    }
    if((_isMobile()) && !window.tronWeb){
        if (tronWeb && provider) {
            // console.log(provider.accounts[0],provider.chainId)
            return provider.accounts[0];
        } 
    }else{
        console.log(tronWeb.defaultAddress);
        store.commit('set_address',tronWeb.defaultAddress.base58)
        return tronWeb.defaultAddress.base58;
    }

}

function isErrorTronlinkAndConnect() {

    if(_isMobile() && !window.tronWeb){
        let msg = "";
        if (provider && provider.accounts[0]) {
            let accounts = provider.accounts[0];
            if(!accounts){
                toToast(msg)
                return 'Not connected to account'
            }   
        }else{
            msg = "Not connected to wallet";
            toToast(msg)
            return msg;  
        }
       
    }else{
        if (!window.tronWeb) {
            let msg = " please install TronLink  plugin！";
            return msg;
        }
       
        if (!window.tronWeb.contract) {
            let msg = " the wallet connect is break ,please try again connect !";
            return msg;
        }
        tronWeb = window.tronWeb
        return false;
    }
}
//trx数量
async function trxBalance(addr, callback) {

    var err = isErrorTronlinkAndConnect();
    if (err) {

        callback(err);
        return;
    }

    var balance = await tronWeb.trx.getBalance(addr);
    callback(null, balance);
}

//trx转账
async function trxTransfer(toAddr, amount, callback) {

    var err = isErrorTronlinkAndConnect();
    if (err) {
        callback(err);
        return;
    }
    let tx = await tronWeb.trx.sendTransaction(toAddr, amount * Math.pow(10, 6));

    callback(null, tx);
}


//获取链
function getChain() {
    var err = isErrorTronlinkAndConnect(); 
    return new Promise((resolve, reject) => {
        if (err) {
            console.log(err);
            return;
        }
    if((_isMobile()) && !window.tronWeb){
            if(provider.chainId){
                resolve(provider.chainId) 
            }
            if(provider.fullNode&&provider.fullNode.host){
                resolve(provider.fullNode.host)
            }

        }else{
            resolve(tronWeb.fullNode.host)
        }
    }).catch(err=>{
        console.log(err);
    }) 
}

 //余额
 async function balanceOf(tokenAddr, from, callback) {


    var instance = await window.tronWeb.contract().at(tokenAddr);
    var ownerAddr = getCurrentAccount();
    instance.balanceOf(from).call({ from: ownerAddr }).then(function(result) {

        console.log(result);

        callback(result);
    })

}

//授信额度
async function allowance(tokenAddr, sender, spender, callback) {

    var instance = await window.tronWeb.contract().at(tokenAddr);

    instance.allowance(sender, spender).call().then(function(result) {

        console.log(result);

        callback(result);
    })
}

 //授信
 async function approve(tokenAddr, spender, amount, callback) {

    var ownerAddr = getCurrentAccount();
    var instance = await window.tronWeb.contract().at(tokenAddr);

    instance.approve(spender, amount).send({ from: ownerAddr }).then(function(result) {
        console.log(result);

        callback(result);
    })
}

 //增加授信
 async function increaseAllowance(tokenAddr, spender, amount, callback) {

    var ownerAddr = getCurrentAccount();
    var instance = await window.tronWeb.contract().at(tokenAddr);

    instance.increaseAllowance(spender, amount).send({ from: ownerAddr }).then(function(result) {
        console.log(result);

        callback(result);
    })
}

 //转账
 async function transfer(tokenAddr, toAddr, amount, callback) {

    var ownerAddr = getCurrentAccount();
    var instance = await window.tronWeb.contract().at(tokenAddr);


    instance.transfer(toAddr, amount).send({ from: ownerAddr }).then(function(result) {

        console.log(result);
    })
}

 //授信划转
 async function transferFrom(tokenAddr, sender, spender, amount, callback) {

    var ownerAddr = getCurrentAccount();
    var instance = await window.tronWeb.contract().at(tokenAddr);

    instance.transferFrom(sender, spender, amount).send({ from: ownerAddr }).then(function(result) {

        console.log(result);

        callback(result);
    })
}

App.TRC20 = {
    balanceOf: balanceOf,
    approve: approve,
    allowance: allowance,
    increaseAllowance: increaseAllowance,
    transferFrom,
    transfer: transfer,
    trxBalance: trxBalance,
    trxTransfer: trxTransfer
}

App.TronLink = {
    init: init,
    getContractInstance: getContractInstance,
    reconnect: reconnect,
    getCurrentAccount: getCurrentAccount,
    getChain: getChain,
    isTronLink: isErrorTronlinkAndConnect,
}

export default App