import {router} from '@/router'
import { showToast } from 'vant'


export function _isMobile() {      
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)      
    return flag;    
}
export function isPhoneNumber(str) {
    var myreg = /^\d+$/;
    if (!myreg.test(str)) {
        return false;
    } else {
        return true;
    }
}

export function getQueryVariable(variable)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

export function starPhone(phoneNum){
    let str = String(phoneNum),
        strLen = str.slice(0, 3) +'****' + str.slice(str.length-4);
    return strLen;
}


export function goLink(url,query){
    router.push({
        path:url,
        query:query
    })
}

export function toToast(msg){
    showToast(msg)
}

export function getformToken(str){
    if(str){
        return str.substr(0,4)+'...'+str.substr(str.length-4)
    }else{
        return str
    }
  }