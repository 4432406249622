import {toToast} from '@/utils'
import {
    getToken,getChainId} from "@/utils/auth"

    import { showLoadingToast } from 'vant'; 

// export const baseUrl = 'https://lw.qdkj123.com/'
export const baseUrl = location.protocol+'//'+window.location.host

//验证是否连接到钱包 
export function checkConnect(){
    if(!getToken()){
        toToast('请连接钱包')
        return false
    }
    // console.log(getChainId())
    // if(getChainId()){
    //     if(getChainId()!='97' && getChainId()!='56' && getChainId()!='5'){
    //         toToast('网络连接错误，请更换网络')
    //         return false
    //     }
    // }

    return true
}

export function getToast(){
    showLoadingToast({
        duration:0,
        message: '请等待链上确认...',
        forbidClick: true,
      });
}